<template>
  <div class="grow center wrap">
    <form @submit.prevent="post" class="container center d-col">
      <s-logo />
      <h1 class="d-flex green--text font-48 text-center">
        <div class="bold">Customer Portal</div>
      </h1>
      <div class="missingText textRed">
        <div v-if="message">
          {{ message }}
        </div>
      </div>
      <div  v-if="message == 'Please use a customers account email address.'">
          <router-link class="green--text mt-15 font-14 d-flex center" to="/partners/login">
            <div>Link to partner portal</div>
          </router-link>
        </div>
      <s-text-field v-model="username" details block :error="userError" :label="$t('login.email')"></s-text-field>
      <s-text-field v-model="password" type="password" block password details :error="passwordError" :label="$t('login.password')"></s-text-field>
      <s-btn block class="green mt-15" height="40">
        {{ $t("login.submit") }}
      </s-btn>
      <div class="d-flex w100 space-between">
        <router-link class="green--text mt-15 font-14 d-flex center" to="/lostpassword">
          <div>{{ $t("login.forgot") }}</div>
        </router-link>
        <router-link class="green--text mt-15 font-14 d-flex center" to="/registration">
          <div>{{ $t("login.signup") }}</div>
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      message: "",
      userError: "",
      passwordError: "",
    };
  },
  methods: {
    async post() {
      let response = await this.$store.dispatch("auth/login", {
        email: this.username,
        password: this.password,
      });

      this.userError = "";
      this.passwordError = "";
      this.message = "";

      if (this.username == "") this.userError = this.$t("login.enter_email");
      if (this.password == "") this.passwordError = this.$t("login.enter_password");
      if (response.errorCode == "incorrect_email_password") this.message = this.$t("server." + response.errorCode);
      if (response.user?.isReseller) return this.message = 'Please use a customers account email address.'

      if (response.status == "ok") {
        await this.$store.dispatch("user/getUserInformation");
        await this.$store.dispatch("products/getProducts");
        await this.$store.dispatch("licenses/getLicenses");
        if (this.$router.name != "CustomerLogin") this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped lang="less">
.container {
  width: 100%;
  max-width: 400px;
  margin: 10px;
}

.textRed {
  color: #df5151;
}
</style>