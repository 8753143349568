<template>
  <div class="h100 vh100 d-flex d-col">
    <TopToolbar />
    <Login />
  </div>
</template>

<script>
import Login from "@/components/Auth/Login/CustomerLogin.vue";
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";

export default {
  name: "Register",
  components: {
    Login,
    TopToolbar,
  },
};
</script>

